import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type PublishContentType = {
  creatorContent: CreatorContent;
  setCreatorContent: Dispatch<SetStateAction<CreatorContent>>;
  clearPublishContent: () => void;
};

type CreatorContent = {
  medias: string[];
  collaborators: string[];
  shareLocation: string;
  listConsumer: string[];
  isExclusiveContent: boolean;
  rosesContentPrice: number;
  message: string;
};

const PublishContentContext = createContext<PublishContentType | undefined>(
  undefined
);

export const PublishContentProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [creatorContent, setCreatorContent] = useState<CreatorContent>({
    medias: [],
    collaborators: [],
    shareLocation: "",
    listConsumer: [],
    isExclusiveContent: false,
    rosesContentPrice: 0,
    message: "",
  });

  const clearPublishContent = () => {
    setCreatorContent({
      medias: [],
      collaborators: [],
      shareLocation: "",
      listConsumer: [],
      isExclusiveContent: false,
      rosesContentPrice: 0,
      message: "",
    });
  };

  return (
    <PublishContentContext.Provider
      value={{ creatorContent, setCreatorContent, clearPublishContent }}
    >
      {children}
    </PublishContentContext.Provider>
  );
};

export const usePublishContent = () => {
  const context = useContext(PublishContentContext);

  if (!context) {
    throw new Error(
      "usePublishContent must be used inside a PublishContentProvider"
    );
  }

  return context;
};
