import { ReactNode } from "react";
import { router } from "../routes";
import { RouteObject } from "react-router-dom";

interface Route {
  path: string;
  element: ReactNode;
}

const extractRoutesArray = (): Route[] => {
  return router.routes.map((route: RouteObject) => ({
    path: route.path || "",
    element: route.element || null,
  }));
};

export const getElementByPath = (path: string): ReactNode => {
  const routes = extractRoutesArray();
  const route = routes.find((route) => route.path === path);
  return route ? route.element : null;
};
