import React, { createContext, useState, useContext, useEffect } from 'react';
import { PWAContextData, ProviderProps } from '../types/interfaces';

const PWAContext = createContext<PWAContextData>({} as PWAContextData);

const PwaProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
	const [isPwa, setIsPwa] = useState<boolean>(false);

  useEffect(() => {
    if (isRunningStandalone()) {
      setIsPwa(true);
    } else {
      setIsPwa(false);
    }
  }, []);

  const isRunningStandalone = () => {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

	return (
		<PWAContext.Provider
			value={{
				isPwa,
			}}
		>
			{children}
		</PWAContext.Provider>
	);
};

const usePwa = (): PWAContextData => {
	const context = useContext(PWAContext);

	return context;
};

export { PwaProvider, usePwa };