import React, { ReactNode } from "react";
import "./styles.scss";

interface BackgroundComponentLPProps {
  children: ReactNode;
  className?: string;
}

const BackgroundComponentLP: React.FC<BackgroundComponentLPProps> = (props) => {
  const { children, className } = props;
  return (
    <div id="background-component-lp" className={className}>
      {children}
    </div>
  );
};

export default BackgroundComponentLP;
