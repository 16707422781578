import React, { useEffect, useState } from "react";
import "./styles.scss";

interface RangeProps {
  min: number;
  max: number;
  rangeCallback?: (value: string) => void;
  step?: number;
  className?: string;
  value?: string | number;
}

const Range: React.FC<RangeProps> = (props) => {
  const { max, min, rangeCallback, step, className, value } = props;
  const [rangeValue, setRangeValue] = useState(value);

  const onChangeRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeValue(event.target.value);
    if (rangeCallback) {
      rangeCallback(event.target.value);
    }
  };

  useEffect(() => {
    setRangeValue(value);
  }, [value]);

  return (
    <div id="range-default" className={className ?? ""}>
      <input
        type="range"
        id="range_val"
        min={min}
        max={max}
        value={rangeValue}
        onChange={onChangeRange}
        step={step}
      />
    </div>
  );
};

export default Range;
