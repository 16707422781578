import React, { useState } from "react";
import "./styles.scss";
import Toggle from "../../Toggle/SimpleToggle";
import Category from "../../Category";
import Range from "../../Ranges/Range";
import InputText from "../../Inputs/InputText";
import sphereIconGray from "../../../assets/icons/sphere-item-icon-gray.svg";

interface ListItemProps {
  showSphereIcon?: boolean;
  showIconLeft?: boolean;
  showIconBorderLeft?: boolean;
  showCheckboxLeft?: boolean;
  showRadioLeft?: boolean;
  showIconMain?: boolean;
  showLabel?: boolean;
  showDescription?: boolean;
  showSlider?: boolean;
  showValue?: boolean;
  showInfo?: boolean;
  showCategory?: boolean;
  showRadioRight?: boolean;
  showToggle?: boolean;
  showCheckboxRight?: boolean;
  showCounter?: boolean;
  showIconRight?: boolean;
  showSeparator?: boolean;
  iconLeftContent?: string;
  iconMainContent?: string;
  iconRightContent?: string;
  labelContent?: string;
  descriptionContent?: string;
  valueContent?: string | number;
  infoContent?: string;
  categoryContent?: string;
  showInput?: boolean;
  onChangeInput?: (a: string) => void;
  valueInput?: string;
  contactList?: boolean;
  initialsContact?: string;
  checkBoxLeftHandler?: (a: string) => void;
  checkBoxRightHandler?: (a: string) => void;
  radioLeftValue?: string;
  radioLeftGroupName?: string;
  radioRightValue?: string;
  radioRightGroupName?: string;
  onChangeRightRadioCallback?: (a: string) => void;
  onChangeLeftRadioCallback?: (a: string) => void;
  onToggleChange?: (isToggled: boolean) => void;
  clickAction?: () => void;
  className?: string;
  minSliderValue?: number;
  maxSliderValue?: number;
  callBackSlider?: (value: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  showSphereIcon = false,
  showIconLeft = false,
  showIconBorderLeft = false,
  showCheckboxLeft = false,
  showRadioLeft = false,
  showIconMain = false,
  showLabel = false,
  showDescription = false,
  showSlider = false,
  showValue = false,
  showInfo = false,
  showCategory = false,
  showRadioRight = false,
  showToggle = false,
  showCheckboxRight = false,
  showCounter = false,
  showIconRight = false,
  showSeparator = false,
  iconLeftContent,
  iconMainContent,
  iconRightContent,
  labelContent = "Label",
  descriptionContent = "Description",
  valueContent = "Value",
  infoContent = "Info",
  categoryContent = "Category",
  showInput = false,
  onChangeInput,
  valueInput,
  contactList,
  initialsContact,
  checkBoxLeftHandler,
  checkBoxRightHandler,
  radioLeftValue,
  radioLeftGroupName,
  radioRightValue,
  radioRightGroupName,
  onChangeRightRadioCallback,
  onChangeLeftRadioCallback,
  onToggleChange,
  clickAction,
  className,
  minSliderValue,
  maxSliderValue,
  callBackSlider,
}) => {
  const [toggleState, setToggleState] = useState(false);

  const handleToggleChange = (newState: boolean) => {
    setToggleState(newState);
    if (onToggleChange) {
      onToggleChange(newState);
    }
  };

  return (
    <div
      //PRECISA REVISAR
      className={`list-item-container ${className ? className : ""}`}
      onClick={clickAction}
    >
      {showSphereIcon && (
        <img
          className="sphere-icon margin-right-16 padding-8"
          src={sphereIconGray}
          alt=""
        />
      )}
      {showIconLeft && (
        <div
          className={`icon-left margin-right-16 ${
            showIconBorderLeft ? "icon-left-border" : ""
          }`}
        >
          <img src={iconLeftContent} alt="" />
        </div>
      )}
      {showCheckboxLeft && checkBoxLeftHandler && (
        <label className="checkbox-left">
          <input
            type="checkbox"
            onChange={(e) => checkBoxLeftHandler(e.target.value)}
          />
          <span className="checkmark"></span>
        </label>
      )}
      {showRadioLeft && onChangeLeftRadioCallback && (
        <div className="radio-container">
          <input
            type="radio"
            className="list-radio"
            value={radioLeftValue}
            name={radioLeftGroupName}
            onChange={(e) => onChangeLeftRadioCallback(e.target.value)}
          />
        </div>
      )}
      {contactList && (
        <div className="initials-contact margin-right-16">
          <p>{initialsContact}</p>
        </div>
      )}

      {showIconMain && (
        <div className="icon-main">
          <img src={iconMainContent} alt="" />
        </div>
      )}

      <div className="text-container">
        {showLabel && (
          <label className="label__lp" textkey={labelContent}></label>
        )}
        {showDescription && (
          <p
            className="description__lp no-margin"
            textkey={descriptionContent}
          />
        )}
      </div>

      {showSlider && minSliderValue && maxSliderValue && callBackSlider && (
        <Range
          min={minSliderValue}
          max={maxSliderValue}
          rangeCallback={callBackSlider}
        />
      )}
      {showValue && <div className="value margin-left-16">{valueContent}</div>}
      {showInfo && <div className="info">{infoContent}</div>}
      {showCategory && <Category children={categoryContent} />}

      {showRadioRight && onChangeRightRadioCallback && (
        <div className="radio-container">
          <input
            type="radio"
            className="list-radio"
            value={radioRightValue}
            name={radioRightGroupName}
            onChange={(e) => onChangeRightRadioCallback(e.target.value)}
          />
        </div>
      )}
      {showToggle && <Toggle onChange={handleToggleChange} />}
      {showCheckboxRight && checkBoxRightHandler && (
        <label className="checkbox-right">
          <input
            type="checkbox"
            onChange={(e) => checkBoxRightHandler(e.target.value)}
          />
          <span className="checkmark"></span>
        </label>
      )}

      {showInput && onChangeInput && (
        <InputText
          textAlign="right"
          onChangeInput={onChangeInput}
          value={valueInput || ""}
          inputType="text"
        />
      )}
      {showCounter && <div className="counter">Counter</div>}
      {showIconRight && (
        <div className="icon-right margin-left-16">
          <img src={iconRightContent} alt="" />
        </div>
      )}
      {showSeparator && <div className="separator"></div>}
    </div>
  );
};

export default ListItem;
