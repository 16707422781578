import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ToggleProps {
  onChange: (isToggled: boolean) => void;
  initialState?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ onChange, initialState = false }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

  const toggle = () => {
    const toggled = !isToggled;
    setIsToggled(toggled);
    onChange(toggled);
  };

  return (
    <div className="toggle-container" onClick={toggle}>
      <div className={`toggle-switch ${isToggled ? "on" : "off"}`}>
        <div
          className={`toggle-handle ${isToggled ? "handle-on" : "handle-off"}`}
        ></div>
      </div>
    </div>
  );
};

export default Toggle;
