import React, { createContext, ReactNode, useState } from "react";

interface ToggleChatOpenContextProps {
  isChatPageOpen: boolean;
  setIsChatPageOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ToggleChatPageProviderProps {
  children: ReactNode;
}

export const ToggleChatOpenContext = createContext(
  {} as ToggleChatOpenContextProps
);

export const ToggleChatPageProvider: React.FC<ToggleChatPageProviderProps> = ({
  children,
}) => {
  const [isChatPageOpen, setIsChatPageOpen] = useState(false);

  return (
    <ToggleChatOpenContext.Provider
      value={{ isChatPageOpen, setIsChatPageOpen }}
    >
      {children}
    </ToggleChatOpenContext.Provider>
  );
};
