import React, { ReactNode } from "react";
import { AuthProvider } from "./auth";
import { PwaProvider } from "./pwa";
import { DeskNavigationProvider } from "./useDeskNavigation";
import { LoginSignUpProvider } from "./useLoginSignUpNavigation";
import { UserInfoProvider } from "./userInfo";
import { ToggleChatPageProvider } from "../contexts/toggleChatOpen";
import { FilesProvider } from "./documentContext";
import { ModalProvider } from "./useModal";
import { ChatProvider } from "../contexts/openedChatContext";
import { UseWindowWidthSizeProvider } from "./useWindowWidthSize";
import { PublishContentProvider } from "./usePublishContent";
import { WebSocketProvider } from "../contexts/webSocketContext";

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <PwaProvider>
      <AuthProvider>
        <UserInfoProvider>
          <WebSocketProvider>
            <DeskNavigationProvider>
              <LoginSignUpProvider>
                <PublishContentProvider>
                  <FilesProvider>
                    <UseWindowWidthSizeProvider>
                      <ModalProvider>
                        <ChatProvider>
                          <ToggleChatPageProvider>
                            {children}
                          </ToggleChatPageProvider>
                        </ChatProvider>
                      </ModalProvider>
                    </UseWindowWidthSizeProvider>
                  </FilesProvider>
                </PublishContentProvider>
              </LoginSignUpProvider>
            </DeskNavigationProvider>
          </WebSocketProvider>
        </UserInfoProvider>
      </AuthProvider>
    </PwaProvider>
  );
};

export default AppProvider;
