import React, { ReactNode } from "react";
import "./styles.scss";
import BackgroundComponentLP from "../../Wrappers/BackgroundComponentLP";
import Button from "../../Buttons/Button";
import Category from "../../Category";
import TranslatableText from "../../Texts/TranslatableText";
import HeaderFeature from "../../Headers/HeaderFeature";

interface CardsFeaturesLPProps {
  buttonText?: ReactNode;
  buttonClick?: (a: any) => void;
  theme:
    | "engagement"
    | "userContent"
    | "comprehensiveDashboard"
    | "aiContent"
    | "security";
}

const CardsFeaturesLP: React.FC<CardsFeaturesLPProps> = (props) => {
  const { buttonClick, buttonText, theme } = props;

  const engagement = [
    {
      id: 0,
      icon: "photo",
      title: "4/featureTitle-01",
      subTitle: "4/featureDescription-01",
    },
    {
      id: 1,
      icon: "video",
      title: "4/featureTitle-02",
      subTitle: "4/featureDescription-02",
    },
    {
      id: 2,
      icon: "underwear",
      title: "4/featureTitle-03",
      subTitle: "4/featureDescription-03",
    },
  ];

  const userContent = [
    {
      id: 0,
      icon: "roses-outline",
      title: "4/featureTitle-04",
      subTitle: "4/featureDescription-04",
    },
    {
      id: 1,
      icon: "percent",
      title: "4/featureTitle-05",
      subTitle: "4/featureDescription-05",
    },
    {
      id: 2,
      icon: "marketplace",
      title: "4/featureTitle-06",
      subTitle: "4/featureDescription-06",
    },
  ];

  const comprehensiveDashboard = [
    {
      id: 0,
      icon: "dashboard     ",
      title: "4/featureTitle-07",
      subTitle: "4/featureDescription-07",
    },
    {
      id: 1,
      icon: "burn",
      title: "4/featureTitle-08",
      subTitle: "4/featureDescription-08",
    },
    {
      id: 2,
      icon: "star-outline",
      title: "4/featureTitle-09",
      subTitle: "4/featureDescription-09",
    },
  ];

  const aiContent = [
    {
      id: 0,
      icon: "messages",
      title: "4/featureTitle-10",
      subTitle: "4/featureDescription-10",
    },
    {
      id: 1,
      icon: "selfie",
      title: "4/featureTitle-11",
      subTitle: "4/featureDescription-11",
    },
    {
      id: 2,
      icon: "heart-outline",
      title: "4/featureTitle-12",
      subTitle: "4/featureDescription-12",
    },
  ];
  const security = [
    {
      id: 0,
      icon: "lock",
      title: "4/featureTitle-13",
      subTitle: "4/featureDescription-13",
    },
    {
      id: 1,
      icon: "verified-1",
      title: "4/featureTitle-14",
      subTitle: "4/featureDescription-14",
    },
    {
      id: 2,
      icon: "bank",
      title: "4/featureTitle-15",
      subTitle: "4/featureDescription-15",
    },
  ];

  const cardDetailsHandler = () => {
    switch (theme) {
      case "userContent":
        return userContent;
      case "comprehensiveDashboard":
        return comprehensiveDashboard;
      case "engagement":
        return engagement;
      case "aiContent":
        return aiContent;
      case "security":
        return security;
      default:
        return [];
    }
  };

  return (
    <div id="roses-features">
      {cardDetailsHandler().map((features) => (
        <BackgroundComponentLP key={features.id}>
          <HeaderFeature
            hasGapBetweenTitleAndSub={false}
            marginTop={0}
            headerIcon={features.icon as any}
            title={features.title}
            subTitle={
              <TranslatableText fontSize="16px" textKey={features.subTitle} />
            }
          />
          {theme === "engagement" && features.id === 2 && (
            <div className="pill-engagement">
              <Category>
                <TranslatableText textKey="4/featureComingSoon" />
              </Category>
            </div>
          )}
          {theme === "aiContent" && (
            <div className="pill-engagement">
              <Category>
                <TranslatableText textKey="4/featureAI" />
              </Category>
            </div>
          )}
        </BackgroundComponentLP>
      ))}
      {buttonText && buttonClick && (
        <Button onClick={buttonClick} buttonStyle="primary">
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default CardsFeaturesLP;
