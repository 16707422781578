import React from "react";

import "./styles.scss";

interface IChatBubbleImage {
  side: string;
  message?: string;
  time?: string;
  translateDisclaimerContent?: string;
  countryFlag?: string;
}

const ChatBubbleImage: React.FC<IChatBubbleImage> = ({
  side,
  message,
  time,
  translateDisclaimerContent,
  countryFlag,
}: IChatBubbleImage) => {
  const messageClass = `chat-message ${side}`;
  const containerClass = `chat-message__container ${side}`;

  return (
    <>
      {side === "left" && <img className="flag margin-bottom-4" src={countryFlag} />}
      {side === "right" && (
        <p className="translate-disclaimer translate-disclaimer-right no-margin">
          {translateDisclaimerContent}
        </p>
      )}

      <div className={containerClass}>
        <div className={messageClass}>
          <p className="chat-message__content">{message}</p>
          <p className="chat-time margin-right-16">{time}</p>
        </div>
      </div>
      {side === "left" && (
        <p className="translate-disclaimer">{translateDisclaimerContent}</p>
      )}
      <div className="flag-image-container">
        {side === "right" && (
          <img className="flag-right margin-top-8" src={countryFlag} />
        )}
      </div>
    </>
  );
};

export default ChatBubbleImage;
