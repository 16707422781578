import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

interface OpenedChatContextProps {
  activeChatId: string | null;
  setActiveChatId: (id: string | null) => void;
}

const OpenedChatContext = createContext<OpenedChatContextProps | undefined>(
  undefined
);

export const useChat = () => {
  const context = useContext(OpenedChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeChatId, setActiveChatId] = useState<string | null>(null);

  return (
    <OpenedChatContext.Provider value={{ activeChatId, setActiveChatId }}>
      {children}
    </OpenedChatContext.Provider>
  );
};
